import React from "react"
import PropTypes from "prop-types"
import Seo from "./seo"
import Header from "./Header/header"
import Footer from "./Footer/footer"
import Contact from "./Contact/contact"
import { FullScreen, useFullScreenHandle } from "react-full-screen"

const Layout = ({ children }) => {
  const handle = useFullScreenHandle()

  return (
    <>
      <Seo />
      <Header handle={handle} />
      <FullScreen handle={handle}>
        <main id="container">
          <Contact />
          {children}
        </main>
      </FullScreen>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
