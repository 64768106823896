import React from "react"
import "./footer.css"

let title = "Experts in brazed plate heat exchangers"
let description = "SWEP is a world-leading supplier of brazed plate heat exchangers for HVAC and industrial applications. Designed to make the most efficient use of energy, material, and space, the technology is quickly winning ground around the world, with SWEP at the forefront of developments";
let more = "More about SWEP";
let moreLink = "https://www.swep.net/company/company/";
if (typeof window !== 'undefined') {
  if (window.location.href.toLowerCase().indexOf("lang=cn") > 0) { 
    description = "舒瑞普（SWEP）是空调、制冷、 采暖和工业应用的钎焊板式换热器供应商。其拥有的钎焊板式换热技术能够有效利用能源、材料和空间，设计理念因而正迅速占领全球市场，舒瑞普（SWEP）也因此处于行业发展的前端。"; 
    title = "钎焊板式换热器专家";  
    more = "有关舒瑞普（SWEP）的更多资讯";
    moreLink = "https://www.swep.cn/company/-swep/";
  };
  if (window.location.href.toLowerCase().indexOf("lang=de") > 0) { 
    description = "SWEP ist ein weltweit führender Anbieter von gelöteten Plattenwärmetauschern für Heizungs-, Klima- und Kältetechnik und Industrieanwendungen. Die Technologie wurde entwickelt, um Energie, Material und den vorhanden Raum so effizient wie möglich zu nutzen. Sie gewinnt weltweit schnell an Bedeutung, wobei SWEP an der Spitze der Entwicklungen steht"; 
    title = "Experten für gelötete Plattenwärmetauscher";  
    more = "Mehr über SWEP";
    moreLink = "https://www.swep.de/company/uber-swep/";
  };
  if (window.location.href.toLowerCase().indexOf("lang=fr") > 0) { 
    description = "SWEP est un leader mondial de la fabrication d’échangeurs thermiques à plaques brasées pour diverses applications industrielles, installations de chauffage, de climatisation et de ventilation. Conçue pour garantir une utilisation efficace de l’énergie, des matériaux et de l’espace, la technologie gagne du terrain sur le marché mondial, et permet à SWEP de rester à la pointe de l’innovation."; 
    title = "Experts des échangeurs thermiques à plaques brasées";  
    more = "En savoir plus sur SWEP";
    moreLink = "https://www.swep.fr/entreprise/-propos-de-swep/";
  };
  if (window.location.href.toLowerCase().indexOf("lang=pl") > 0) { 
    description = "SWEP to wiodący światowy dostawca lutowanych płytowych wymienników ciepła do instalacji HVAC i systemów przemysłowych. Ta technologia powstała, aby zoptymalizować zużycie energii, materiałów oraz miejsca. Szybko zdobywa popularność na całym świecie, a firma SWEP jest liderem w jej rozwijaniu"; 
    title = "Specjaliści w dziedzinie płytowych, lutowanych wymienników ciepła";  
    more = "Więcej o firmie SWEP";
    moreLink = "https://www.swep.net/company/company/";
  };
}

const Footer = () => (
  
  <div className="footer">
    <div className="footer-container">
      <div className="footer-col-left">
        <h3>{title}</h3>
        <p>
          { description }
        </p>
        <a
          href={moreLink}
          target="_blank"
          rel="noreferrer"
        >
          {more}
        </a>
      </div>
      <div className="footer-col-right">

      </div>      
    </div>
  </div>
)

export default Footer
