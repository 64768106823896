import React from "react"
import { Link } from "gatsby"
import "./header.scss"

let title = "View in fullscreen";
if (typeof window !== 'undefined') {
  if (window.location.href.toLowerCase().indexOf("lang=cn") > 0) { title = "全屏查看" };
  if (window.location.href.toLowerCase().indexOf("lang=de") > 0) { title = "Vollbildansicht" };
  if (window.location.href.toLowerCase().indexOf("lang=fr") > 0) { title = "Afficher en mode plein écran" };
  if (window.location.href.toLowerCase().indexOf("lang=pl") > 0) { title = "Wyświetl w trybie pełnoekranowym" };
}

const Header = ({ handle }) => {
  return (
    <header>
      <div className="header">
        <Link to="/">
          <img src={"/logo.png"} alt="Swep logo" className="header-logo" />
        </Link>
        <button onClick={handle.enter}>{title}</button>
      </div>
    </header>
  )
}

export default Header
