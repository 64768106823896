import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../../fontawesome';
import "./contact.scss"

let title = "Contact a sales person";
let url = "https://www.swep.net/company/contacts/";
let lang = "en";
if (typeof window !== 'undefined') {
    if (window.location.href.toLowerCase().indexOf("lang=cn") > 0) { 
        title = "联系销售人员";
        url = "https://www.swep.cn/company/3/";
        lang = "cn";
    };
    if (window.location.href.toLowerCase().indexOf("lang=de") > 0) { 
        title = "Wenden Sie sich an einen Vertriebs-Mitarbeiter";
        lang = "de";
    };
    if (window.location.href.toLowerCase().indexOf("lang=fr") > 0) { 
        title = "Contacter un représentant";
        url = "https://www.swep.fr/entreprise/contactez-nous/?type=sales";
        lang = "fr";
    };
    if (window.location.href.toLowerCase().indexOf("lang=pl") > 0) { 
        title = "Skontaktuj się ze sprzedawcą";
        lang = "pl";
    };
}

let cssClass = "contact " + lang;

const Contact = ({ handle }) => {
    let screenWidth = 300;
    let screenHeight = 300;
    if (typeof window !== `undefined`) {
        screenWidth = window.innerWidth;
        screenHeight = window.innerWidth - 90;
    }

    let videoWidth = (screenHeight * (16 / 9));
    let videoLeft = (screenWidth - videoWidth) / 2;
    let videoRight = videoLeft + videoWidth;

    //let menu = document.getElementById("contact");
    //menu.style.left = videoRight + "px";  

    return (
        <div id="contact" className={cssClass}>
            <div className="contact-button">
                <a href={url} target="_blank">
                    <FontAwesomeIcon icon={['fa', 'user']} />
                    <span>{title}</span>
                </a>
            </div>        
        </div>
    )
}

export default Contact
